import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { getPreview } from "libs/previewApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';

// Define the props type for StepIcon
interface StepIconProps {
  stepCount: number;
}

// Custom Icon Component
const StepIcon: React.FC<StepIconProps> = ({ stepCount }) => (
  <Box
    borderRadius="50%"
    backgroundColor="#3965FF"
    color="#fff"
    display={"flex"}
    margin={"auto"}
    alignItems={"center"}
    justifyContent={"center"}
    fontWeight={"bold"}
    w={"100%"}
    h={"100%"}
  >
    {stepCount}
  </Box>
);

interface EmailDetailsProps {
  activeStep: number;
}

const PreviewSteps: React.FC<EmailDetailsProps> = ({ activeStep }) => {
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<
    {
      waitTime: string;
      subject: string;
      emodProfile: string;
      body: string;
      date: string;
      active: boolean;
    }[]
  >([]);
  const history = useHistory();

  const fetchPreview = async () => {
    let campId = sessionStorage.getItem("campaignId");
    const response = await getPreview(campId);
    try {
      if (response?.status === 200) {
        setSteps(response.data);
      } else {
        toast.error(response?.statusText);
      }
    } catch (error) {
      toast.error("Failed to fetch preview data");
    }
  };

  useEffect(() => {
    fetchPreview();
  }, [activeStep]);

  const handleClose = () => {
    setLoading(true);
    setTimeout(() => {
      history.push("/admin/campaigns");
      sessionStorage.removeItem("campaignId");
      sessionStorage.removeItem("campaignEdit")
      setLoading(false);
    }, 1000);
  };

  return (
    <Box  mt={8} p={6} borderWidth={1} borderRadius="lg" boxShadow="sm">
      <Box mb={4}>
        <VerticalTimeline layout="1-column-left" className="demoCustomLine" lineColor="#ccc">
          {steps.map((step, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                border: "1px solid #ccc",
                boxShadow: "none",
                color: "#000",
              }}
              contentArrowStyle={{
                borderRight: "7px solid rgb(33, 150, 243)",
              }}
              icon={<StepIcon stepCount={index + 1} />}
              iconStyle={{ background: "none", color: "inherit" }}
            >
            

              {/* Accordion for HTML preview */}
              <Accordion allowToggle  border={0} borderBottomWidth={0}>
                <AccordionItem border={0}>
                  <AccordionButton
                  py={0}
                  alignItems={'center'}
                    _focus={{ outline: "none" }} // Disable focus outline
                    _hover={{ backgroundColor: "transparent" }} // Remove hover effect
                    _active={{ backgroundColor: "transparent" }} // Remove active effect
                    border={0}
                    _expanded={{ border: "none" }} // Remove border when expanded
                  >
                    <Box flex="1" textAlign="left" >
                    <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <Text fontSize="20">{String.fromCharCode(65 + index)}</Text>
                <Text fontSize="20" fontWeight="bold">{step.waitTime}</Text>
                <Text fontSize="20" fontWeight="bold">{step.subject}</Text>
                <Text fontSize="20" fontWeight="bold">{step.emodProfile}</Text>
              </Box>
                    </Box>
                    <AccordionIcon mt={2} />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {/* Render HTML content safely */}
                    <Box
      borderWidth="1px"
      borderRadius="md"
      p={3}
      bg="gray.50"
    >
      {parse(step.body)}
    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>

        <Button
          display={"flex"}
          justifyContent="flex-end"
          marginLeft="auto"
          width="auto"
          colorScheme="blue"
          mt={4}
          onClick={() => handleClose()}
          rightIcon={<ChevronRightIcon />}
        >
          {loading ? (
            <Flex alignItems="center">
              <Text mr="2">Continue</Text>
              <Spinner size="sm" mr="2" />
            </Flex>
          ) : (
            "Continue"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default PreviewSteps;
