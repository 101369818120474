import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Input,
	Select,
	Spinner,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { addEmailAccounts, checkMailExist, getAllsendingSchedular, getOptionsForTimeZone } from 'libs/emailAccount';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

interface NewAccountType {
	name: string;
	email: string;
	aliasTitle: string;
	refreshToken: string;
	throttleStartingVolume: string;
	throttleEndingVolume: string;
	daysTillFullThrottle: string;
	emailDelay: string;
	sendingSchedularId: string;
	timeZone: string;
	isAuthenticated: boolean;
	[key: string]: string | boolean; // Adding index signature to support dynamic key access
}


export default function AddEmail() {
	// Chakra Color Mode
	const boxBg = useColorModeValue('white', 'gray.800');
	const history = useHistory();
	const [schedularData, setSchedularData] = useState([])
	const [timezoneData, setTimezoneData] = useState([])
	const [loading, setLoading] = useState(false)

	const [newAccount, setNewAccount] = useState<NewAccountType>({
		name: '',
		email: '',
		aliasTitle: '',
		refreshToken: '',
		throttleStartingVolume: '15',
		throttleEndingVolume: '100',
		daysTillFullThrottle: '20',
		emailDelay: '66',
		sendingSchedularId: '',
		timeZone: '',
		isAuthenticated: false,
	});

	const clearData = () => {
		setNewAccount({
			name: '',
			email: '',
			aliasTitle: '',
			refreshToken: '',
			throttleStartingVolume: '15',
			throttleEndingVolume: '100',
			daysTillFullThrottle: '20',
			emailDelay: '66',
			sendingSchedularId: '',
			timeZone: '',
			isAuthenticated: false,
		})
	}

	const [errors, setErrors] = useState({
		name: '',
		email: '',
		throttleStartingVolume: '',
		throttleEndingVolume: '',
		aliasTitle: '',
		daysTillFullThrottle: '',
		emailDelay: '',
		sendingSchedularId: '',
		timeZone: '',
	});

	const validateField = (name: string, value: any): string => {
		let error = '';
		switch (name) {
			case 'name':
				if (!value) {
					error = 'Please enter name';
				} else
					if (!/^[a-zA-Z\s]+$/.test(value)) {
						error = 'Please enter valid name';
					}
				break;
			case 'aliasTitle':
				if (!value) {
					error = 'Please enter title';
				}
				// if (!/^[a-zA-Z\s]+$/.test(value)) {
				// 	error = 'Please enter valid title';
				// }
				break;
			case 'email':
				if (!value) {
					error = 'Please enter email';
				} else
					if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
						error = 'Please enter valid email address.';
					}
				break;
			case 'throttleStartingVolume':
			case 'throttleEndingVolume':
				if (!value) {
					error = 'Please enter Volume';
				} else
					if (!/^\d+$/.test(value)) {
						error = 'Volume should be a number.';
					}
				break;
			case 'daysTillFullThrottle':
				if (!value) {
					error = 'Please enter throttle';
				} else
					if (!/^\d+$/.test(value)) {
						error = 'Please enter a valid throttle number.';
					}
				break;
			case 'emailDelay':
				if (!value) {
					error = 'Please enter email delay';
				} else
					if (!/^\d+$/.test(value)) {
						error = 'Please enter a valid email delay number.';
					}
				break;
			case 'sendingSchedularId':
				if (!value) {
					error = 'Please select a Sending Schedule.';
				}
				break;
			case 'timeZone':
				if (!value) {
					error = 'Please select a Timezone.';
				}
				break;
			default:
				error = '';
		}
		return error;
	};
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		const error = validateField(name, value);
		setNewAccount({
			...newAccount,
			[name]: value,
		});
		setErrors({
			...errors,
			[name]: error,
		});
	};

	const handleAddAccount = async () => {
		const newErrors: any = {};
		Object.keys(newAccount).forEach((key) => {
			const error = validateField(key, newAccount[key]);
			if (error) {
				newErrors[key] = error;
			}
		});
		setErrors(newErrors);
		if (errors.email !== "") {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: "From email already exist",
			}));
			return;
		}
		if (Object.keys(newErrors).length === 0) {
			setLoading(true);
			try {
				const response = await addEmailAccounts(newAccount);
				if (response?.status === 200) {
					toast.success("Email Account Added Successfully");
					clearData();
					onCancel();
				} else {
					toast.error(response?.statusText || "Failed to add email account.");
				}
			} catch (error) {
				toast.error("An error occurred while adding the account. Please try again.");
				console.error("Error adding email account:", error);
			} finally {
				setLoading(false);
			}
		}
	};

	const fetchSchedular = async () => {
		let response = await getAllsendingSchedular()
		try {
			if (response?.status == 200) {
				setSchedularData(response?.data?.sendingSchedulars)
			} else {
				console.log(response)
			}
		} catch (error) {
		}
	}

	const fetchTimeZone = async () => {
		let response = await getOptionsForTimeZone("TimeZone")
		try {
			if (response?.status == 200) {
				setTimezoneData(response?.data)
			} else {
				console.log(response)
			}
		} catch (error) {
		}
	}

	const debouncedCheckMail = debounce(async (email) => {
		let response = await checkMailExist(email);
		try {
			if (response?.status === 200) {
				if (response.data.data !== null) {
					setErrors((prevErrors) => ({
						...prevErrors,
						email: response.data.message,
					}));
				} else {
					setErrors((prevErrors) => ({
						...prevErrors,
						email: "",
					}));
				}
			} else {
				console.log(response);
			}
		} catch (error) {
			console.error(error);
		}
	}, 500);

	useEffect(() => {
		fetchSchedular()
		fetchTimeZone()
	}, [])

	useEffect(() => {
		if (newAccount.email) {
			debouncedCheckMail(newAccount.email);
		}
	}, [newAccount.email]);

	// email auth code
	// start Authenticate email
	const handleConfigure = async () => {
		try {
			debugger;
			const response = "SUCCESS"
			if (response === "SUCCESS") {
				const loginHint = ''; // Set loginHint to empty string or required value
				const scope = encodeURIComponent(process.env.REACT_APP_SCOPE);
				const redirect_uri_encode = encodeURIComponent(process.env.REACT_APP_REDIRECT_URL);
				const client_id = encodeURIComponent(process.env.REACT_APP_CLIENT_ID);
				const response_type = "code";
				const access_type = "offline";
				var state = ""
				// if (EmailAccountID != null) {
				// 	state = "editemail," + EmailAccountID;
				// }
				// else {
				state = "addemail";
				// }
				const approval_prompt = "force";

				// Construct the Google OAuth URL
				const Url = `https://accounts.google.com/o/oauth2/auth?scope=${scope}&redirect_uri=${redirect_uri_encode}&response_type=${response_type}&client_id=${client_id}&state=${state}&access_type=${access_type}&approval_prompt=${approval_prompt}&login_hint=${loginHint}`;

				// Redirect to the constructed URL
				window.location.href = Url;
			} else {
				toast.error(response || "An error occurred.");
			}
		} catch (error) {
			console.error("Error during configuration:", error);
			toast.error("Failed to configure the account. Please try again.");
		}
	};

	useEffect(() => {
		const hash = window.location.hash;
		const params = new URLSearchParams(hash.split('?')[1]);
		const encodedData = params.get('data');
		if (encodedData) {
			const decodedData = atob(encodedData);
			const paramsArray = decodedData.split('__');
			const email = paramsArray[0].split('=')[1];
			const refreshToken = paramsArray[1].split('=')[1];
			setNewAccount({
				name: '',
				email: email,
				aliasTitle: '',
				refreshToken: refreshToken,
				throttleStartingVolume: '15',
				throttleEndingVolume: '100',
				daysTillFullThrottle: '20',
				emailDelay: '66',
				sendingSchedularId: '',
				timeZone: '',
				isAuthenticated: true,
			})
		}
	}, []);
	// end Authenticate email
	const onCancel = () => {
		history.push('/admin/emailaccounts');
	};

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Box bg={boxBg} borderRadius="lg" p={6} boxShadow="lg" mx="auto">
				<Grid templateColumns="repeat(2, 1fr)" gap={4}>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.email}>
							<FormLabel>Email</FormLabel>
							<Input
								name="email"
								placeholder='Please enter email'
								value={newAccount.email}
								onChange={handleInputChange}
								disabled={newAccount?.refreshToken ? true : false}
							/>
							{errors.email && <Box color="red.500">{errors.email}</Box>}
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl>
							<FormLabel
								sx={{
									visibility: 'hidden',
								}}
							>
								Authenticate
							</FormLabel>
							<Button
								variant="brand"
								onClick={() => handleConfigure()}
								disabled={newAccount?.refreshToken ? true : false}
							>
								{newAccount?.refreshToken ?
									"Authenticate"
									: "Authenticated"
								}
							</Button>
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.name}>
							<FormLabel>Full Name</FormLabel>
							<Input
								name="name"
								placeholder='Please enter full name'
								value={newAccount.name}
								onChange={handleInputChange}
							/>
							{errors.name && <Box color="red.500">{errors.name}</Box>}
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.aliasTitle}>
							<FormLabel>Alias Title</FormLabel>
							<Input
								name="aliasTitle"
								placeholder='Please enter alias title'
								value={newAccount.aliasTitle}
								onChange={handleInputChange}
							/>
							{errors.aliasTitle && <Box color="red.500">{errors.aliasTitle}</Box>}
						</FormControl>
					</GridItem>
					{/* <GridItem colSpan={1}>
						<FormControl>
							<FormLabel>Refresh Token</FormLabel>
							<Input
								name="refreshToken"
								value={newAccount.refreshToken}
								onChange={handleInputChange}
							/>
						</FormControl>
					</GridItem> */}
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.throttleStartingVolume}>
							<FormLabel>Throttle Starting Volume</FormLabel>
							<Input
								name="throttleStartingVolume"
								placeholder='Please enter throttle starting volume'
								value={newAccount.throttleStartingVolume}
								onChange={handleInputChange}
							/>
							{errors.throttleStartingVolume && <Box color="red.500">{errors.throttleStartingVolume}</Box>}
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.throttleEndingVolume}>
							<FormLabel>Throttle Ending Volume</FormLabel>
							<Input
								name="throttleEndingVolume"
								placeholder='Please enter throttle ending volume'
								value={newAccount.throttleEndingVolume}
								onChange={handleInputChange}
							/>
							{errors.throttleEndingVolume && <Box color="red.500">{errors.throttleEndingVolume}</Box>}
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.daysTillFullThrottle}>
							<FormLabel>Days Till Full Throttle</FormLabel>
							<Input
								name="daysTillFullThrottle"
								placeholder='Please enter full throttle'
								value={newAccount.daysTillFullThrottle}
								onChange={handleInputChange}
							/>
							{errors.daysTillFullThrottle && <Box color="red.500">{errors.daysTillFullThrottle}</Box>}
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.emailDelay}>
							<FormLabel>Email Delay</FormLabel>
							<Input
								name="emailDelay"
								placeholder='Please enter email delay'
								value={newAccount.emailDelay}
								onChange={handleInputChange}
							/>
							{errors.emailDelay && <Box color="red.500">{errors.emailDelay}</Box>}
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.sendingSchedularId}>
							<FormLabel>Sending Schedule</FormLabel>
							<Select
								name="sendingSchedularId"
								value={newAccount.sendingSchedularId}
								onChange={handleInputChange}
								placeholder="Select Schedular"
							>
								{schedularData.map((schedular) => (
									<option key={schedular._id} value={schedular._id}>
										{schedular.scheduleStartTime} - {schedular.scheduleEndTime}
									</option>
								))}
							</Select>
							{errors.sendingSchedularId && <Box color="red.500">{errors.sendingSchedularId}</Box>}
						</FormControl>
					</GridItem>
					<GridItem colSpan={1}>
						<FormControl isInvalid={!!errors.timeZone}>
							<FormLabel>Time Zone</FormLabel>
							<Select
								name="timeZone"
								value={newAccount.timeZone}
								onChange={handleInputChange}
								placeholder="Select Timezone"
							>
								{timezoneData.map((time) => (
									<option key={time._id} value={time._id}>
										{time.fieldName}
									</option>
								))}
							</Select>
							{errors.timeZone && <Box color="red.500">{errors.timeZone}</Box>}
						</FormControl>
					</GridItem>
					{/* <GridItem colSpan={1}>
						<FormControl display="flex" alignItems="center" pb="10px">
							<FormLabel mb={0}>Is Authenticated</FormLabel>
							<Checkbox
								name="isAuthenticated"
								isChecked={newAccount.isAuthenticated}
								onChange={(e) =>
									setNewAccount({
										...newAccount,
										isAuthenticated: e.target.checked,
									})
								}
							/>
						</FormControl>
					</GridItem> */}
				</Grid>
				<Box display="flex" justifyContent="flex-end" alignItems="center" mt={4} gap="10px">
					<Button variant="brand" onClick={handleAddAccount} >
						{loading ? (
							<Flex alignItems='center'>
								<Text mr='2' >Save </Text>
								<Spinner size='sm' mr='2' />
							</Flex>
						) : (
							'Save'
						)}
					</Button>
					<Button color="primary" border="1px" onClick={onCancel}>
						Cancel
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
