import {
  deleteApiCaller,
  newGetApiCaller,
  newPostApiCaller,
  postApiCaller,
  putApiCaller,
} from "./apiCaller";

export const getAllStep = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await newGetApiCaller(
      `api/campaigns/get-all-campaign-template/${id}`,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};
export const getStepById = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await newGetApiCaller(
      `api/campaigns/get-campaign-template/${id}`,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};
export const addStep = async (
  newStep,
  selectedFields,
  campaignId,
  stepNumber,
  bodyContent
) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      eModProfileId: selectedFields.emodProfileId,
      stepNumber: stepNumber,
      days: newStep.waitTime,
      subject: newStep.subject,
      body: bodyContent,
    };

    const response = await newPostApiCaller(
      "api/campaigns/add-campaign-template",
      body,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const updateStep = async (
  newStep,
  selectedFields,
  stepNumber,
  id,
  bodyContent,
  campaignId
) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      eModProfileId: selectedFields.emodProfileId,
      stepNumber: stepNumber,
      days: newStep.waitTime,
      subject: newStep.subject,
      body: bodyContent,
    };

    const response = await putApiCaller(
      `api/campaigns/update-campaign-template/${id}`,
      body,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const uploadImage = async (file) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const formData = new FormData();
    formData.append("file", file);
    const response = await newPostApiCaller(
      `upload-image`,
      formData,
      { headers }
    );

    return response;
  } catch (error) {
    console.error("Error uploading image:", error || error);
  }
};

export const deleteStep = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const response = await deleteApiCaller(
      `api/campaigns/delete-campaign-template/${id}`,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};
