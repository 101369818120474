import React, { useState, useEffect } from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Text,
  Input,
  Table,
  Thead,
  Grid,
  GridItem,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Icon,
  Spinner,
  useColorModeValue,
  Flex,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import { FaFileImport, FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { deleteContact, getAllContactData } from "libs/apiFunctions";
import Pagination from "./Pagination";
import ImportEmailModal from "./ImportEmailModal/ImportEmailModal";
import { getAllEmailAccounts } from "libs/emailAccount";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";


type AccountRow = {
  _id: string;
  email: string;
  aliasTitle: string;
  createdDate: string;
  isActive: boolean;
  isAuthenticated: boolean;
  isDeleted: boolean;
  lastUpdatedDate: string;
  name: string;
  refreshToken: string;
  daysTillFullThrottle: number;
  emailDelay: number;
  imapHost: string;
  imapPassword: string;
  imapPort: string;
  throttleEndingVolume: number;
  throttleStartingVolume: number;
  scheduleStartTime: string | null;
  scheduleEndTime: string | null;
  scheduleStartUTCTime: string | null;
  scheduleEndUTCTime: string | null;
  timeZone: string | null;
};

type formFields = {
  name: string;
  email: string;
  aliasTitle: string;
  refreshToken: string;
  throttleStartingVolume: number;
  throttleEndingVolume: number;
  daysTillFullThrottle: number;
  emailDelay: number;
  sendingSchedularId: string;
  timeZone: string;
  isAuthenticated: boolean
};

type ReportingRow = {
  name: string;
  smtpEmail: string;
  totalOpenPercentage: string;
  l30OpenPercentage: string;
  l7OpenPercentage: string;
  totalSends: number;
  l30Sends: number;
  l7Sends: number;
};

const initialReportingData: ReportingRow[] = [
  {
    name: "John Doe",
    smtpEmail: "smtp@example.com",
    totalOpenPercentage: "75%",
    l30OpenPercentage: "80%",
    l7OpenPercentage: "70%",
    totalSends: 1000,
    l30Sends: 200,
    l7Sends: 50,
  },
  {
    name: "Jane Smith",
    smtpEmail: "smtp2@example.com",
    totalOpenPercentage: "65%",
    l30OpenPercentage: "70%",
    l7OpenPercentage: "60%",
    totalSends: 800,
    l30Sends: 150,
    l7Sends: 40,
  },
];

export default function TabbedTables() {
  const [accountData, setAccountData] = useState<AccountRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [reportingData, setReportingData] =
    useState<ReportingRow[]>(initialReportingData);

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [newAccount, setNewAccount] = useState<formFields>({
    name: "",
    email: "",
    aliasTitle: "",
    refreshToken: "",
    throttleStartingVolume: 0,
    throttleEndingVolume: 0,
    daysTillFullThrottle: 0,
    emailDelay: 0,
    sendingSchedularId: "",
    timeZone: "",
    isAuthenticated: false,
  });


  const [isImportModalOpen, setImportModalOpen] = useState(false); // New state for the import modal

  const history = useHistory();

  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgHover = useColorModeValue({ bg: 'primary.100' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

  const handleaddemail = () => {
    history.push('/admin/addemail');
  }
  const handleeditemail = (id: string) => {
    sessionStorage.setItem("emailId", id)
    history.push('/admin/editemail');
  }
  const handleImportModalOpen = () => {
    setImportModalOpen(true);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  // Pass fetchAccountData to ImportContactModal
  const importModalProps = {
    isOpen: isImportModalOpen,
    onClose: handleImportModalClose,
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const fetchAccountData = async () => {
    setLoading(true);
    try {
      const response = await getAllEmailAccounts(searchText, pageNumber);
      if (response.status == 200) {
        setAccountData(response?.data?.accounts || []);
        setTotalPages(response?.data?.totalPages || 1);
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {
      console.error("Error fetching account data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountData();
  }, [searchText, pageNumber]);

  const handleDelete = async (id: string) => {
    try {
      await deleteContact(id);
      toast.success("Email account successfully deleted")
      fetchAccountData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  // const handleChange = (e, setter, regex) => {
  //   setter(e.target.valeu)
  // }
  // Filtered data based on search text
  useEffect(() => {
    fetchAccountData();
  }, [searchText, pageNumber]);

  const filteredData = accountData.filter((account) =>
    account.name.toLowerCase().includes(searchText.toLowerCase())
  );
  const handleAddAccount = () => {
    // setAccountData((prev) => [...prev, newAccount]);
    setNewAccount({
      name: "",
      email: "",
      aliasTitle: "",
      refreshToken: "",
      throttleStartingVolume: 0,
      throttleEndingVolume: 0,
      daysTillFullThrottle: 0,
      emailDelay: 0,
      sendingSchedularId: "",
      timeZone: "",
      isAuthenticated: false,
    });
    setAddModalOpen(false);
  };

  return (
    <Box bg="white" borderRadius="16px" p="4" width="100%" mx="auto">
      <Tabs variant="soft-rounded" colorScheme="primary">
        <TabList>

          {/* <Tab
            sx={{
              boxShadow: "none",
              "&:focus": {
                boxShadow: "none",
              },
              "&.chakra-tabs__tab[aria-selected='true']": {
                color: "white",
                fontWeight: "bold",
                border: "2px solid blue",
                borderRadius: "20px",
                borderBottomColor: "blue",
                backgroundColor: "#422AFB",
              },
            }}
          >
            Accounts
          </Tab> */}

          {/* <Tab
            sx={{
              boxShadow: "none",
              "&:focus": {
                boxShadow: "none",
              },
              "&.chakra-tabs__tab[aria-selected='true']": {
                color: "white",
                fontWeight: "bold",
                border: "2px solid blue",
                borderRadius: "20px",
                borderBottomColor: "blue",
                backgroundColor: "#422AFB",
              },
            }}
          >
            Reporting
          </Tab> */}

        </TabList>

        {/* Accounts Table */}

        <TabPanels>
          {/* Accounts Table */}
          <TabPanel p={0}>
            <Box
              display={"flex"}
              pb={5}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Tooltip label="Add" borderRadius={8} fontSize="sm" >
                <Button variant="brand" mx={2} onClick={handleaddemail}>
                  <Icon as={FaPlus} mx={2} /> Add
                </Button>
              </Tooltip>

              <Input
                placeholder="Search..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                borderRadius="30px"
                w="200px"
                mx="10px"
              />
            </Box>

            {loading ? (
              <Flex justify="center" align="center" height="200px">
                <Spinner size="lg" />
                <Text ml="4" fontSize="lg" color={textColor}>
                  Loading...
                </Text>
              </Flex>
            ) : (
              <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {[
                        "Authenticate",
                        "Name",
                        "Alias Title",
                        "From Email",
                        "SMTP Email",
                        "SMTP Host",
                        "SMTP Port",
                        "IMAP Email",
                        "IMAP Host",
                        "IMAP Port",
                        "Throttle Start Volume",
                        "Throttle End Volume",
                        "Email Delay",
                        "Days Till Full Throttle",
                        "Today's Potential Volume",
                        "SMTP Security",
                        "IMAP Security",
                        "Sending Schedule",
                        "Email Warning Sending",
                        "Email Warning Receiving",
                        "Time Zone",
                        "Actions",
                      ].map((header) => (
                        <Th key={header} whiteSpace="nowrap"
                          fontSize={{ sm: '10px', lg: '12px' }} color='black'
                        >
                          {header}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {accountData?.map((row) => (
                      <Tr key={row?._id}
                        color={textColor} fontSize='sm' fontWeight='700'
                      >
                        <Td>{row?.isAuthenticated ? "Yes" : "No"}</Td>
                        <Td>{row?.name}</Td>
                        <Td>{row?.aliasTitle}</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>{row?.imapHost}</Td>
                        <Td>{row?.imapPort}</Td>
                        <Td>{row?.throttleStartingVolume}</Td>
                        <Td>{row?.throttleEndingVolume}</Td>
                        <Td>{row?.emailDelay}</Td>
                        <Td>{row?.daysTillFullThrottle}</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>{row?.scheduleStartTime}</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>{row?.timeZone}</Td>
                        <Td>
                          <>

                          </>
                          <Flex>

                            <Flex>
                              <Tooltip label="Edit" borderRadius={8} fontSize="sm">
                                <Button
                                  alignItems="center"
                                  justifyContent="center"
                                  bg={bgButton}
                                  _hover={bgHover}
                                  _focus={bgFocus}
                                  _active={bgFocus}
                                  w="37px"
                                  h="37px"
                                  lineHeight="100%"
                                  mx={2}
                                  ms={0}
                                  borderRadius="10px"
                                  onClick={() => handleeditemail(row?._id)}
                                >
                                  <Icon as={FaRegEdit} color={iconColor} w="20px" h="20px" />
                                </Button>
                              </Tooltip>
                            </Flex>

                            <Flex>
                              <Tooltip label="Delete" borderRadius={8} fontSize="sm">
                                <Button
                                  alignItems="center"
                                  justifyContent="center"
                                  bg={bgButton}
                                  _hover={bgHover}
                                  _focus={bgFocus}
                                  _active={bgFocus}
                                  w="37px"
                                  h="37px"
                                  lineHeight="100%"
                                  mx={2}
                                  ms={0}
                                  borderRadius="10px"
                                  onClick={() => handleDelete(row?._id)}
                                >
                                  <Icon as={FaRegTrashAlt} color={iconColor} w="20px" h="20px" />
                                </Button>
                              </Tooltip>
                            </Flex>
                          </Flex>
                          {/*  <Button onClick={() => handleDelete(row?._id)}>
                            <Icon as={FaRegTrashAlt} />
                          </Button>
                          <Button onClick={() => handleeditemail(row?._id)}>
                            <Icon as={FaRegEdit} />
                          </Button> */}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
            {!loading && accountData.length == 0 &&
              <p>Not Data Found.</p>
            }
            {!loading && accountData.length > 0 &&
              <Pagination
                currentPage={pageNumber}
                totalPages={totalPages}
                onPageChange={setPageNumber}
              />
            }
          </TabPanel>

        </TabPanels>
      </Tabs>

      <ImportEmailModal {...importModalProps} /> {/* Add modal component here */}

    </Box>
  );
}
