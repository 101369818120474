import { deleteApiCaller, newDeleteApiCaller, newGetApiCaller, newPostApiCaller, postApiCaller, putApiCaller } from "./apiCaller";

export const getOptions = async (fieldName) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      fieldName: fieldName,
    };

    const response = await postApiCaller("api/commonfields/get-fields", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const addCampaigns = async (campaignName, selectedFields, note) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      name: campaignName,
      replyBehaviorId: selectedFields.replyBehaviourId,
      campaignPriorityId: selectedFields.priorityId,
      emailOpenTrackingId: selectedFields.openTrackingId,
      eModProfileId: selectedFields.emodProfileId,
      isSendonWeekends: selectedFields.sendOnWeekends,
      isIgnoreBlacklistedContacts: selectedFields.ignoreBlacklist,
      isTrackClick: selectedFields.trackClicks,
      isSubjectPrompt: selectedFields.subjectPrompt,
      notes: note,
    };

    const response = await postApiCaller("api/campaigns/add", body, {
      headers,
    });

    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const updateCampaign = async (campaignName, selectedFields, note, id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      name: campaignName,
      replyBehaviorId: selectedFields.replyBehaviourId,
      campaignPriorityId: selectedFields.priorityId,
      emailOpenTrackingId: selectedFields.openTrackingId,
      eModProfileId: selectedFields.emodProfileId,
      isSendonWeekends: selectedFields.sendOnWeekends,
      isIgnoreBlacklistedContacts: selectedFields.ignoreBlacklist,
      isTrackClick: selectedFields.trackClicks,
      isSubjectPrompt: selectedFields.subjectPrompt,
      notes: note,
    };

    const response = await putApiCaller(`api/campaigns/update/${id}`, body, {
      headers,
    });

    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const getCampaignDetail = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await newGetApiCaller(
      `api/campaigns/get/${id}`,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const getCampaignsEmailAccount = async () => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: "66cdaaf7da20855bc6c12753",
      search: "",
      page: 1,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await postApiCaller(
      "api/campaigns/get-all-campaign-email-accounts",
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const getCampaignsAuthenticatedEmailAccount = async (campaignId, searchText) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      search: searchText,
      page: 1,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await newPostApiCaller(
      "api/campaigns/get-all-authenticate-email-accounts",
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const assignEmailAccount = async (campaignId, emailId) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      emailAccountId: emailId,
    };

    console.log(body);

    const response = await newPostApiCaller(
      "api/campaigns/assign-campaign-email-accounts",
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const getAssignEmail = async (campaignId, page) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      search: "",
      page: page,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await newPostApiCaller(
      "api/campaigns/get-all-campaign-email-accounts",
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const deleteAssignEmail = async (campaignId, emailId) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      emailAccountId: emailId,
    };

    const response = await newDeleteApiCaller(
      "api/campaigns/delete-campaign-email-account",
      body,
      headers
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const deleteCampaign = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
    };

    const response = await newDeleteApiCaller(
      `api/campaigns/delete/${id}`,
      body,
      headers
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

