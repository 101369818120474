import {
  deleteApiCaller,
  getApiCaller,
  newPostApiCaller,
  postApiCaller,
  postForGetApiCaller,
  putApiCaller,
} from "./apiCaller";

export const signIn = async (userEmail, userPassword) => {
  try {
    let headers = {
      "Content-Type": "application/json",
    };

    let body = {
      email: userEmail,
      password: userPassword,
    };

    let response = await postApiCaller("api/users/login", body, { headers });
    return response;
  } catch (error) {}
};

export const checkToken = async () => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    let response = await getApiCaller("api/users/check-auth-token", { headers });
    return response;
  } catch (error) {}
};

export const addContact = async (formData) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const body = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
      phone: formData?.phone,
      websiteDomain: formData?.websiteDomain,
      linkdedInUrl: formData?.linkdedInUrl,
    };

    const response = await postApiCaller("api/contacts/add", body, { headers });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const editContact = async (formData, id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const body = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
      phone: formData?.phone,
      websiteDomain: formData?.websiteDomain,
      linkdedInUrl: formData?.linkdedInUrl,
    };

    const response = await putApiCaller(`api/contacts/update/${id}`, body, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const deleteContact = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await deleteApiCaller(`api/accounts/delete/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const getAllContactData = async (searchText, pageNumber) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const body = {
      search: searchText,
      page: pageNumber,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await postApiCaller("api/contacts/all", body, { headers });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

// group
export const contactBulkImport = async (files) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const formData = new FormData();

    // Append each file to FormData
    files.forEach((file) => {
      formData.append("file", file);
    });

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await postApiCaller(
      "api/contacts/contacts-bulk-import",
      formData,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error importing contacts:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const contactBulkImportWithGroup = async (
  groupName,
  files
) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const formData = new FormData();

    formData.append("name", groupName);
    files.forEach((file) => {
      formData.append("file", file);
    });

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await postApiCaller("api/groups/add", formData, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error importing contacts:", error);
    return { error: error.message }; // Return error message or object
  }
};
export const checkGroupName = async (groupName) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await getApiCaller(
      `api/groups/check-group-exist?groupName=${groupName}`,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error importing contacts:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const getAllGroup = async () => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {};

    const response = await postApiCaller("api/groups/all", body, { headers });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

// campaigns
export const getAllCampaigns = async () => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      search: "",
      page: 1,
      limit: 20,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await newPostApiCaller("api/campaigns/all", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const addCampaigns = async (
  campaignName,
  description,
  summary,
  email,
  selectedDate,
  assignGroupId
) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const userData = JSON.parse(sessionStorage?.getItem("userData"));
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      name: campaignName,
      description: description,
      summary: summary,
      senderName: `${userData?.firstName} ${userData?.lastName}`,
      senderEmail: email,
      scheduledSendTime: selectedDate,
      assignGroups: [assignGroupId],
    };

    const response = await postApiCaller("api/campaigns/add", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const sendMailHistory = async (page, searchTerm) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      search: searchTerm,
      page: page,
      limit: 10,
      sortBy: "mailSentDateTime",
      sortOrder: "desc",
    };

    const response = await postApiCaller("api/sentmailhistory/all", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};
