import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  TableContainer,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  HStack,
  Flex,
  Text,
  Spinner,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, ChevronRightIcon, AddIcon } from "@chakra-ui/icons";
import { getAllEmailAccounts } from 'libs/emailAccount';
import { toast } from 'react-toastify';
import { assignEmailAccount, deleteAssignEmail, getAssignEmail, getCampaignsAuthenticatedEmailAccount } from 'libs/campaignApi';
import { FaRegTrashAlt } from 'react-icons/fa';

interface EmailAccount {
  _id: string;
  email: string;
  name: string;
  refreshToken: string;
  aliasTitle: string;
  imapHost: string;
  imapPassword: string;
  imapPort: string;
  imapStatus: boolean;
  throttleStartingVolume: number;
  throttleEndingVolume: number;
  daysTillFullThrottle: number;
  emailDelay: number;
  sendingSchedularId: string;
  isAuthenticated: boolean;
  isActive: boolean;
  isDeleted: boolean;
  createdDate: string;
  scheduleStartUTCTime: string;
  scheduleEndUTCTime: string;
  timeZoneId: string;
  timeZone: string;
  scheduleStartTime: string;
  scheduleEndTime: string;
}


interface EmailAccountsResponse {
  status: number;
  statusText: string;
  data: {
    accounts: EmailAccount[];
  };
}


const EMAILS_PER_PAGE = 10;

interface EmailDetailsProps {
  setActiveStep: (step: number) => void;
  activeStep: number
}

const EmailDetails: React.FC<EmailDetailsProps> = ({ setActiveStep, activeStep }) => {

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue(
    { bg: "primary.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setsearchText] = useState("")
  const [emailData, setEmailData] = useState<EmailAccount[]>([]);
  const [addedEmail, setAddedEmail] = useState<EmailAccount[]>([]);
  const [page, setPage] = useState(1)
  const totalPages = page;
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  const fetchEmail = async () => {
    try {
      let campId = sessionStorage.getItem('campaignId')
      let response: EmailAccountsResponse = await getCampaignsAuthenticatedEmailAccount(campId, searchText);
      if (response.status === 200) {
        setEmailData(response.data.accounts)
      } else {
        toast.error(response?.statusText || "Some error occurred");
      }
    } catch (error) {
      console.error("Error fetching email accounts:", error);
    }
  };

  const fetchAssignEmail = async () => {
    try {
      let campId = sessionStorage.getItem('campaignId')
      let response = await getAssignEmail(campId, currentPage);
      if (response.status === 200) {
        setAddedEmail(response.data.campaignEmailAccount)
        setPage(response.data.totalPages)
      } else {
        toast.error(response?.statusText || "Some error occurred");
      }
    } catch (error) {
      console.error("Error fetching email accounts:", error);
    }
  };

  const handleAddEmail = async (id: string) => {
    let campId = sessionStorage.getItem('campaignId')
    const response = await assignEmailAccount(campId, id)
    try {
      if (response.status == 200) {
        toast.success("Email Added Successfully")
        fetchAssignEmail()
        fetchEmail()
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {

    }
  };

  const handleRemoveEmail = async (id: string) => {
    let campId = sessionStorage.getItem('campaignId')
    const response = await deleteAssignEmail(campId, id)
    try {
      if (response.status == 200) {
        toast.success(response.data.message)
        fetchEmail()
        fetchAssignEmail()
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {

    }
  };

  const [campaignEdit, setCampaignEdit] = useState(false)
  useEffect(() => {
    // fetchEmail()
    const dat = sessionStorage?.getItem('campaignEdit')
    if (dat == "true") {
      setCampaignEdit(true)
    } else {
      setCampaignEdit(false)
    }
    fetchAssignEmail()
  }, [activeStep])

  useEffect(() => {
    fetchEmail()
  }, [searchText])

  const handleNext = () => {
    setLoading(true)
    setTimeout(() => {
      setActiveStep(2)
      setLoading(false)
    }, 1000);
  }

  return (
    <Box p={6} borderWidth={1} borderRadius="lg" boxShadow="sm">
      <Button
        onClick={() => {
          handleOpenModal();
          fetchEmail();
        }}
        colorScheme="blue"
        mb={4}
        display={'flex'}
        marginLeft={'auto'}
      >
        Configure Email Account
      </Button>

      {addedEmail?.length == 0 ?
        <p>No data found</p> :
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr fontSize={{ sm: '10px', lg: '12px' }} color='black'>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Name</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Title Name</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Email</Th>
                {/* <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">SMTP Email</Th>
              <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">SMTP Host</Th>
              <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">SMTP Port</Th> */}
                {/* <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">IMAP Email</Th> */}
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">IMAP Host</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">IMAP Port</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Throttle Starting Volume</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Throttle Ending Volume</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Email Delay</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Days Till Full Throttle</Th>
                {/* <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Today's Potential Volume</Th> */}
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {addedEmail?.map((email) => (
                <Tr key={email._id} fontSize='sm' fontWeight='700' color={'#000'}>
                  <Td whiteSpace="nowrap">{email.name}</Td>
                  <Td whiteSpace="nowrap">{email.aliasTitle}</Td>
                  <Td whiteSpace="nowrap">{email.email}</Td>
                  {/* <Td whiteSpace="nowrap">{email.smtpEmail}</Td>
                <Td whiteSpace="nowrap">{email.smtpHost}</Td>
                <Td whiteSpace="nowrap">{email.smtpPort}</Td> */}
                  {/* <Td whiteSpace="nowrap">{email.imapEmail}</Td> */}
                  <Td whiteSpace="nowrap">{email.imapHost}</Td>
                  <Td whiteSpace="nowrap">{email.imapPort}</Td>
                  <Td whiteSpace="nowrap">{email.throttleStartingVolume}</Td>
                  <Td whiteSpace="nowrap">{email.throttleEndingVolume}</Td>
                  <Td whiteSpace="nowrap">{email.emailDelay}</Td>
                  <Td whiteSpace="nowrap">{email.daysTillFullThrottle}</Td>
                  {/* <Td whiteSpace="nowrap">{email.todaysPotentialVolume}</Td> */}
                  <Td whiteSpace="nowrap">
                    <Tooltip label="Delete" borderRadius={8} fontSize="sm">
                      <Button
                        alignItems="center"
                        justifyContent="center"
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w="37px"
                        h="37px"
                        lineHeight="100%"
                        mx={2}
                        ms={0}
                        borderRadius="10px"
                        onClick={() => handleRemoveEmail(email._id)}
                      >
                        <Icon as={FaRegTrashAlt} color={iconColor} w="20px" h="20px" />
                      </Button>
                    </Tooltip>
                    {/* <IconButton aria-label="Edit" icon={<EditIcon />} mr={2} /> */}
                  </Td>
                </Tr>
              ))}

            </Tbody>
          </Table>
        </TableContainer>
      }


      {/* Static Pagination */}
      {addedEmail?.length > 0 &&
        <HStack spacing={4} mt={4} justifyContent='center'>
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          {[...Array(totalPages).keys()].map(page => (
            <Button
              key={page}
              onClick={() => handlePageChange(page + 1)}
              colorScheme={currentPage === page + 1 ? 'blue' : 'gray'}
            >
              {page + 1}
            </Button>
          ))}
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </HStack>
      }


      {addedEmail?.length > 0 &&
        <Button
          display={"flex"}
          justifyContent="flex-end"
          marginLeft="auto"
          width="auto"
          colorScheme="blue"
          mt={4}
          rightIcon={<ChevronRightIcon />}
          onClick={() => handleNext()}
        >
          {loading ? (
            <Flex alignItems='center'>
              <Text mr='2' >{campaignEdit ? 'Update & Next' : 'Save & Next'} </Text>
              <Spinner size='sm' mr='2' />
            </Flex>
          ) : (
            <p>{campaignEdit ? 'Update & Next' : 'Save & Next'}</p>
          )}
        </Button>
      }

      {/* Configure Email Account Modal */}
      <Modal isOpen={isOpen} onClose={handleCloseModal} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configure Email Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder="Search..." mb={4} value={searchText} onChange={(e) => setsearchText(e.target.value)} />
            {emailData?.length == 0 ?
              <p>No data found</p> :
              <Box overflowY={'auto'}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Add</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Name</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Title Name</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Email</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">IMAP Host</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">IMAP Port</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Throttle Starting Volume</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Throttle Ending Volume</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Email Delay</Th>
                      <Th fontSize={{ sm: '10px', lg: '12px' }} color='black' whiteSpace="nowrap">Days Till Full Throttle</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {emailData.map((email) => (
                      <Tr key={email._id}>
                        <Td whiteSpace="nowrap">
                          <IconButton
                            onClick={() => handleAddEmail(email?._id)}
                            aria-label="Add"
                            icon={<AddIcon />}
                            colorScheme="brand"
                          />
                        </Td>
                        <Td whiteSpace="nowrap">{email.name}</Td>
                        <Td whiteSpace="nowrap">{email.aliasTitle}</Td>
                        <Td whiteSpace="nowrap">{email.email}</Td>
                        <Td whiteSpace="nowrap">{email.imapHost}</Td>
                        <Td whiteSpace="nowrap">{email.imapPort}</Td>
                        <Td whiteSpace="nowrap">{email.throttleStartingVolume}</Td>
                        <Td whiteSpace="nowrap">{email.throttleEndingVolume}</Td>
                        <Td whiteSpace="nowrap">{email.emailDelay}</Td>
                        <Td whiteSpace="nowrap">{email.daysTillFullThrottle}</Td>
                      </Tr>
                    ))}
                    {/* Add more rows as needed */}
                  </Tbody>
                </Table>
                {/* Static Pagination */}
              </Box>
            }
            <HStack spacing={4} mt={4} justifyContent='center'>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              {[...Array(totalPages).keys()].map(page => (
                <Button
                  key={page}
                  onClick={() => handlePageChange(page + 1)}
                  colorScheme={currentPage === page + 1 ? 'blue' : 'gray'}
                >
                  {page + 1}
                </Button>
              ))}
              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Save
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EmailDetails;
