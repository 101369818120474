import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson,MdContactMail, MdHome, MdLock, MdOutlineShoppingCart, MdCampaign, MdEmail, MdSettings } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import Contacts from 'views/admin/contacts';
import Campaigns from 'views/admin/campaigns';
import SendEmail from 'views/admin/sendemail';
import SendingsSchedules from 'views/admin/sendingschedules';
import EmailAccounts from 'views/admin/emailaccounts';
import AddEmail from 'views/admin/addemail';
import EditEmail from 'views/admin/editemail';
import CreateCampaigns from 'views/admin/createcampaigns';

const handleBackToDefault = () => {
	// Your implementation
  };

  
const routes: RoutesType[] = [
	{
		name: 'Campaigns List',
		layout: '/admin',
		path: '/campaigns',
		icon: <Icon as={MdCampaign} width='20px' height='20px' color='inherit' />,
		component: Campaigns,
		secondary: true
	},
	{
		name: 'Settings',
		layout: '/admin',
		path: '/settings', // Changed to a unique path for the parent route
		icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
		component: Campaigns, // You can use a dummy component or create a separate settings component
		children: [
			{
				name: 'Sending Schedules',
				layout: '/admin',
				path: '/sendingschedules',
				icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />, // Add an icon
				component: SendingsSchedules,
			},
			{
				name: 'Email Accounts',
				layout: '/admin',
				path: '/emailaccounts',
				icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />, // Add an icon
				component: EmailAccounts,
			},
		],
	},
	// {
	// 	name: 'Contacts',
	// 	layout: '/admin',
	// 	path: '/contacts',
	// 	icon: <Icon as={MdContactMail} width='20px' height='20px' color='inherit' />,
	// 	component: Contacts,
	// 	secondary: true
	// },
	// {
	// 	name: 'Send Email',
	// 	layout: '/admin',
	// 	path: '/sendemail',
	// 	icon: <Icon as={MdEmail} width='22px' height='22px' color='inherit' />,
	// 	component: SendEmail,
	// 	secondary: true
	// },
	{
		name: 'Add Email',
		layout: '/admin',
		path: '/addemail',
		icon: <Icon as={MdEmail} width='22px' height='22px' color='inherit' />,
		component: AddEmail,
		secondary: true
	},
	{
		name: 'Create Campaign',
		layout: '/admin',
		path: '/createcampaign',
		icon: <Icon as={MdEmail} width='22px' height='22px' color='inherit' />,
		component: () => <CreateCampaigns />, // Wrap the component call
		secondary: true,
	},
	{
		name: 'Edit Email',
		layout: '/admin',
		path: '/editemail',
		icon: <Icon as={MdEmail} width='22px' height='22px' color='inherit' />,
		component: EditEmail,
		secondary: true
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered,
		secondary: true,
		hidden: true
	},
];

export default routes;

declare global {
	interface RoutesType {
		name: string;
		layout: string;
		path: string;
		icon: JSX.Element;
		component: () => JSX.Element;
		children?: RoutesType[];
		secondary?: boolean;
		hidden?: boolean;
	}
}

